import { combineReducers } from 'redux';
import chatReducer from './slices/chat';
import extraReducer from './slices/extra';
import pharmacyReducer from './slices/pharmacy';
import userReducer from './slices/user';

// api

import { articleApi } from './api/articleApi';
import { authApi } from './api/authApi';
import { bankCardApi } from './api/bankCardApi';
import { categoryApi } from './api/categoryApi';
import { homePageApi } from './api/homePageApi';
import { medicineApi } from './api/medicineApi';
import { messageApi } from './api/messageApi';
import { offerApi } from './api/offerApi';
import { orderApi } from './api/orderApi';
import { pharmacyApi } from './api/pharmacyApi';
import { pharmacyMedicineApi } from './api/pharmacyMedicineApi';
import { userApi } from './api/userApi';
import { laboratoryApi } from './api/laboratoryApi';
import { analyticApi } from './api/analyticApi';
// ----------------------------------------------------------------------



const rootReducer = combineReducers({
  chat: chatReducer,
  extra: extraReducer,
  user: userReducer,
  pharmacy: pharmacyReducer,

  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [categoryApi.reducerPath]: categoryApi.reducer,
  [bankCardApi.reducerPath]: bankCardApi.reducer,
  [messageApi.reducerPath]: messageApi.reducer,
  [medicineApi.reducerPath]: medicineApi.reducer,
  [pharmacyApi.reducerPath]: pharmacyApi.reducer,
  [pharmacyMedicineApi.reducerPath]: pharmacyMedicineApi.reducer,
  [articleApi.reducerPath]: articleApi.reducer,
  [offerApi.reducerPath]: offerApi.reducer,
  [orderApi.reducerPath]: orderApi.reducer,
  [homePageApi.reducerPath]: homePageApi.reducer,
  [laboratoryApi.reducerPath]: laboratoryApi.reducer,
  [analyticApi.reducerPath]: analyticApi.reducer,
});

export { rootReducer };

