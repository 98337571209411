// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/';
const ROOTS_PHARMACY_DASHBOARD = '/pharmacy/';
// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500',
};

export const PATH_PHARMACY_DASHBOARD = {
  root: ROOTS_PHARMACY_DASHBOARD,
  general: {
    dashboard: path(ROOTS_PHARMACY_DASHBOARD, 'dashboard'),
    pharmacies: path(ROOTS_PHARMACY_DASHBOARD, 'pharmacies'),
    orders: path(ROOTS_PHARMACY_DASHBOARD, 'orders'),
    users: path(ROOTS_PHARMACY_DASHBOARD, 'users'),
    pharmacyMedicine: path(ROOTS_PHARMACY_DASHBOARD, 'pharmacy-medicine'),
    offer: path(ROOTS_PHARMACY_DASHBOARD, 'offer'),
    article: path(ROOTS_PHARMACY_DASHBOARD, 'article'),
    homePage: path(ROOTS_PHARMACY_DASHBOARD, 'home-page'),
    category: path(ROOTS_PHARMACY_DASHBOARD, 'category'),
  },
  chat: {
    root: path(ROOTS_PHARMACY_DASHBOARD, 'chat'),
    new: path(ROOTS_PHARMACY_DASHBOARD, 'chat/new'),
    conversation: path(ROOTS_PHARMACY_DASHBOARD, 'chat/:conversationKey'),
  },
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    dashboard: path(ROOTS_DASHBOARD, 'dashboard'),
    pharmacies: path(ROOTS_DASHBOARD, 'pharmacies'),
    users: path(ROOTS_DASHBOARD, 'users'),
    medicine: path(ROOTS_DASHBOARD, 'medicine'),
    homePage: path(ROOTS_DASHBOARD, 'home-page'),
    category: path(ROOTS_DASHBOARD, 'category'),
  },

  chat: {
    root: path(ROOTS_DASHBOARD, 'chat'),
    new: path(ROOTS_DASHBOARD, 'chat/new'),
    conversation: path(ROOTS_DASHBOARD, 'chat/:conversationKey'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
