import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RTKBaseQuery } from '../../config';

export const pharmacyApi = createApi({
  reducerPath: 'pharmacyApi',
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  tagTypes: ['pharmacyTag'],
  endpoints: (builder) => ({
    getPharmacies: builder.query({
      query: () => `pharmacy`,
      providesTags: ['pharmacyTag'],
    }),

    addPharmacy: builder.mutation({
      query: (body) => ({
        url: `pharmacy`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['pharmacyTag'],
    }),

    updatePharmacy: builder.mutation({
      query: ({ id, body }) => ({
        url: `pharmacy/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['pharmacyTag'],
    }),

    deletePharmacy: builder.mutation({
      query: (id) => ({
        url: `pharmacy/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['pharmacyTag'],
    }),
  }),
});

export const { useAddPharmacyMutation, useDeletePharmacyMutation, useGetPharmaciesQuery, useUpdatePharmacyMutation } =
  pharmacyApi;
