import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

import useAuth from '../hooks/useAuth';
import { useLazyGetMedicinesQuery } from '../redux/api/medicineApi';

// ----------------------------------------------------------------------

const initialState = {
  products: [],
};

const handlers = {
  UPDATE_PRODUCTS: (state, action) => {
    const { products } = action.payload;

    return {
      ...state,
      products: products || [],
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const ProductsContext = createContext({
  ...initialState,
  updateProducts: () => {},
  addOrUpdateProduct: () => {},
});

// ----------------------------------------------------------------------

ProductsProvider.propTypes = {
  children: PropTypes.node,
};

function ProductsProvider({ children }) {
  const { isAuthenticated } = useAuth();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [getProducts] = useLazyGetMedicinesQuery();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const productsJson = localStorage.getItem('admin-medicines');

    if (productsJson) {
      const products = JSON.parse(productsJson);
      updateProducts(products);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        console.log('getProducts');
        const { data, isSuccess } = await getProducts();
        if (isSuccess) updateProducts(data);
      }
    })();
  }, [isAuthenticated]);

  const updateProducts = async (products) => {
    dispatch({
      type: 'UPDATE_PRODUCTS',
      payload: {
        products,
      },
    });

    localStorage.setItem('admin-medicines', JSON.stringify(products));
  };

  const addOrUpdateProduct = async (product) => {
    let products = [];
    const find = (state?.products || []).find((p) => p?._id === product?._id);

    if (find) {
      products = (state?.products || []).map((p) =>
        p?._id === product?._id
          ? {
              _id: product?._id,
              image: product?.image,
              name: product?.name,
              iva: product?.iva,
              eanNo: product?.eanNo,
              supplierName: product?.supplierName,
            }
          : p
      );
    } else {
      products = [
        ...state?.products,
        {
          _id: product?._id,
          image: product?.image,
          name: product?.name,
          iva: product?.iva,
          eanNo: product?.eanNo,
          supplierName: product?.supplierName,
        },
      ];
    }

    localStorage.removeItem('admin-medicines');

    dispatch({
      type: 'UPDATE_PRODUCTS',
      payload: {
        products,
      },
    });

    localStorage.setItem('admin-medicines', JSON.stringify(products));
  };

  return (
    <ProductsContext.Provider
      value={{
        ...state,
        updateProducts,
        addOrUpdateProduct,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
}

export { ProductsContext, ProductsProvider };
