import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RTKBaseQuery } from '../../config';

export const laboratoryApi = createApi({
  reducerPath: 'laboratoryApi',
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  tagTypes: ['LaboratoryTag'],
  endpoints: (builder) => ({
    getLaboratories: builder.query({
      query: () => `laboratory`,
      providesTags: ['LaboratoryTag'],
    }),

    addLaboratory: builder.mutation({
      query: (body) => ({
        url: `laboratory`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['LaboratoryTag'],
    }),

    updateLaboratory: builder.mutation({
      query: ({ id, body }) => ({
        url: `laboratory/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['LaboratoryTag'],
    }),

    deleteLaboratory: builder.mutation({
      query: (id) => ({
        url: `laboratory/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['LaboratoryTag'],
    }),
  }),
});

export const { useAddLaboratoryMutation, useDeleteLaboratoryMutation, useGetLaboratoriesQuery, useUpdateLaboratoryMutation } =
  laboratoryApi;
