import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box } from '@mui/material';

import logos from './new.png';
import { PATH_AFTER_LOGIN } from '../config';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  const logo = (
    <Box sx={{ width: '70%', height: '60%', ...sx }}>
      <img src={logos} alt="ahmad" />
    </Box>
  );

  return <RouterLink to={PATH_AFTER_LOGIN}>{logo}</RouterLink>;
}
