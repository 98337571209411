import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RTKBaseQuery } from '../../config';

export const offerApi = createApi({
  reducerPath: 'offerApi',
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  tagTypes: ['OfferTag'],
  endpoints: (builder) => ({
    getOffers: builder.query({
      query: (pharmacy) => (pharmacy ? `offer?pharmacy=${pharmacy}` : `offer`),
      providesTags: ['OfferTag'],
    }),

    addOffer: builder.mutation({
      query: (body) => ({
        url: `offer`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['OfferTag'],
    }),

    updateOffer: builder.mutation({
      query: ({ id, body }) => ({
        url: `offer/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['OfferTag'],
    }),

    deleteOffer: builder.mutation({
      query: (id) => ({
        url: `offer/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['OfferTag'],
    }),
  }),
});

export const { useAddOfferMutation, useDeleteOfferMutation, useGetOffersQuery, useUpdateOfferMutation } = offerApi;
