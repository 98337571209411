import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import { persistStore } from 'redux-persist';
import { rootReducer } from './rootReducer';
// api
import { articleApi } from './api/articleApi';
import { authApi } from './api/authApi';
import { bankCardApi } from './api/bankCardApi';
import { categoryApi } from './api/categoryApi';
import { homePageApi } from './api/homePageApi';
import { medicineApi } from './api/medicineApi';
import { messageApi } from './api/messageApi';
import { offerApi } from './api/offerApi';
import { orderApi } from './api/orderApi';
import { pharmacyApi } from './api/pharmacyApi';
import { pharmacyMedicineApi } from './api/pharmacyMedicineApi';
import { userApi } from './api/userApi';
import { laboratoryApi } from './api/laboratoryApi';
import { analyticApi } from './api/analyticApi';

// ----------------------------------------------------------------------

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    })
      .concat(userApi.middleware)
      .concat(authApi.middleware)
      .concat(bankCardApi.middleware)
      .concat(messageApi.middleware)
      .concat(medicineApi.middleware)
      .concat(articleApi.middleware)
      .concat(pharmacyApi.middleware)
      .concat(pharmacyMedicineApi.middleware)
      .concat(offerApi.middleware)
      .concat(homePageApi.middleware)
      .concat(orderApi.middleware)
      .concat(laboratoryApi.middleware)
      .concat(analyticApi.middleware)
      .concat(categoryApi.middleware),
});

const persistor = persistStore(store);

const { dispatch } = store;

const useSelector = useAppSelector;

const useDispatch = () => useAppDispatch();

setupListeners(store.dispatch);

export { dispatch, persistor, store, useDispatch, useSelector };
