import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RTKBaseQuery } from '../../config';

export const pharmacyMedicineApi = createApi({
  reducerPath: 'pharmacyMedicineApi',
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  tagTypes: ['PharmacyMedicineApiTag'],
  endpoints: (builder) => ({
    getPharmacyMedicines: builder.query({
      query: (pharmacyId) => (pharmacyId ? `pharmacy-medicine?pharmacyId=${pharmacyId}` : `pharmacy-medicine`),
      providesTags: ['PharmacyMedicineApiTag'],
    }),

    addPharmacyMedicine: builder.mutation({
      query: (body) => ({
        url: `pharmacy-medicine`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PharmacyMedicineApiTag'],
    }),

    updatePharmacyMedicine: builder.mutation({
      query: ({ id, body }) => ({
        url: `pharmacy-medicine/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['PharmacyMedicineApiTag'],
    }),

    deletePharmacyMedicine: builder.mutation({
      query: (id) => ({
        url: `pharmacy-medicine/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PharmacyMedicineApiTag'],
    }),
  }),
});

export const {
  useAddPharmacyMedicineMutation,
  useDeletePharmacyMedicineMutation,
  useUpdatePharmacyMedicineMutation,
  useGetPharmacyMedicinesQuery,
} = pharmacyMedicineApi;
