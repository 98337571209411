import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RTKBaseQuery } from '../../config';

export const medicineApi = createApi({
  reducerPath: 'medicineApi',
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  endpoints: (builder) => ({
    getMedicines: builder.query({
      query: () => `medicine`,
    }),
    getMedicine: builder.query({
      query: (id) => `medicine/${id}`,
    }),

    addMedicine: builder.mutation({
      query: (body) => ({
        url: `medicine`,
        method: 'POST',
        body,
      }),
    }),

    updateMedicine: builder.mutation({
      query: ({ id, body }) => ({
        url: `medicine/${id}`,
        method: 'PUT',
        body,
      }),
    }),

    deleteMedicine: builder.mutation({
      query: (id) => ({
        url: `medicine/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useAddMedicineMutation,
  useDeleteMedicineMutation,
  useGetMedicinesQuery,
  useUpdateMedicineMutation,
  useGetMedicineQuery,
  useLazyGetMedicineQuery,
  useLazyGetMedicinesQuery
} = medicineApi;
