/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useGetPharmaciesQuery } from '../../../redux/api/pharmacyApi';
import { setSelectedPharmacy } from '../../../redux/slices/pharmacy';
import { useDispatch, useSelector } from '../../../redux/store';
import { ArrowIcon } from './NavItem';

NavPharmacyListRoot.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavPharmacyListRoot({ isCollapse }) {
  const selectedPharmacy = useSelector((state) => state.pharmacy?.selectedPharmacy);

  const { data: pharmacies } = useGetPharmaciesQuery();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  if (isCollapse) return null;

  return (
    // eslint-disable-next-line react/jsx-no-comment-textnodes
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/click-events-have-key-events, jsx-a11y/click-events-have-key-events, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          padding: 10,
          borderRadius: 5,
          backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
          color: theme.palette.primary.main,
          marginBottom: open ? 10 : 0,
        }}
        onClick={(_e) => {
          _e.preventDefault();
          setOpen(!open);
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }}>Pharmacies</Typography>
        <ArrowIcon open={open} />
      </div>
      {open
        ? (pharmacies || []).map((pharmacy) => (
            <div
              key={pharmacy?._id}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
                padding: 10,
                borderRadius: 5,
                marginBottom: 10,
                backgroundColor:
                  pharmacy?._id === selectedPharmacy?._id
                    ? alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
                    : alpha(theme.palette.grey[500], theme.palette.action.selectedOpacity),
                color: theme.palette.grey.A700,
              }}
              onClick={(_e) => {
                _e.preventDefault();
                dispatch(setSelectedPharmacy(pharmacy));
                navigate('/pharmacy');
              }}
            >
              <Typography sx={{ textTransform: 'capitalize' }}>{`${pharmacy?.name || ''}`}</Typography>
            </div>
          ))
        : null}
    </div>
  );
}
