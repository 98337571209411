import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RTKBaseQuery } from '../../config';

export const messageApi = createApi({
  reducerPath: 'messageApi',
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  tagTypes: ['MessageTag'],
  endpoints: (builder) => ({
    getMessages: builder.query({
      query: ({ userId, role }) => `message?userId=${userId}&role=${role}`,
      providesTags: ['MessageTag'],
    }),
    getChatUsers: builder.query({
      query: (pharmacy) => (pharmacy ? `message/user?pharmacy=${pharmacy}` : `message/user`),
      providesTags: ['MessageTag'],
    }),
    getLatestMessage: builder.query({
      query: (pharmacyUserId) => (pharmacyUserId ? `message/latest?pharmacyUserId=${pharmacyUserId}` : `message/latest`),
      providesTags: ['MessageTag'],
    }),

    addMessage: builder.mutation({
      query: (body) => ({
        url: `message`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['MessageTag'],
    }),

    updateMessage: builder.mutation({
      query: ({ id, body }) => ({
        url: `message/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['MessageTag'],
    }),

    deleteMessage: builder.mutation({
      query: (id) => ({
        url: `message/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['MessageTag'],
    }),
  }),
});

export const {
  useGetChatUsersQuery,
  useAddMessageMutation,
  useDeleteMessageMutation,
  useGetMessagesQuery,
  useUpdateMessageMutation,
  useGetLatestMessageQuery,
  useLazyGetLatestMessageQuery,
} = messageApi;
