// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
};

const navConfig = () => [
  {
    subheader: 'general',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.dashboard, icon: ICONS.user },
      { title: 'Users', path: PATH_DASHBOARD.general.users, icon: ICONS.user },
      { title: 'Pharmacies', path: PATH_DASHBOARD.general.pharmacies, icon: ICONS.user },
      { title: 'Medicines', path: PATH_DASHBOARD.general.medicine, icon: ICONS.user },
      { title: 'Categories', path: PATH_DASHBOARD.general.category, icon: ICONS.user },
      { title: 'Chats', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
    ],
  },
];

export default navConfig;
