import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RTKBaseQuery } from '../../config';

export const homePageApi = createApi({
  reducerPath: 'homePageApi',
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  tagTypes: ['HomePageTag'],
  endpoints: (builder) => ({
    getHomePageSetting: builder.query({
      query: (pharmacyId) => (pharmacyId ? `home-page?pharmacy=${pharmacyId}` : `home-page`),
      providesTags: ['HomePageTag'],
    }),

    editHomePageSetting: builder.mutation({
      query: ({ body, pharmacy }) => ({
        url: `home-page`,
        method: 'POST', // Change 'POST' to 'PUT'
        body: { ...body, pharmacy },
      }),
      invalidatesTags: ['HomePageTag'],
    }),
  }),
});

export const { useEditHomePageSettingMutation, useGetHomePageSettingQuery } = homePageApi;
