import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RTKBaseQuery } from '../../config';

export const orderApi = createApi({
  reducerPath: 'orderApi',
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  tagTypes: ['OrderTag'],
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: (pharmacyId) => (pharmacyId ? `order/pharmacy?pharmacy=${pharmacyId}` : `order/pharmacy`),
      providesTags: ['OrderTag'],
    }),

    addOrder: builder.mutation({
      query: (body) => ({
        url: `order`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['OrderTag'],
    }),

    updateOrder: builder.mutation({
      query: ({ id, body }) => ({
        url: `order/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['OrderTag'],
    }),

    deleteOrder: builder.mutation({
      query: (id) => ({
        url: `order/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['OrderTag'],
    }),
  }),
});

export const { useAddOrderMutation, useDeleteOrderMutation, useGetOrdersQuery, useUpdateOrderMutation } = orderApi;
