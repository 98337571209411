import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RTKBaseQuery } from '../../config';

export const categoryApi = createApi({
  reducerPath: 'categoryApi',
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  tagTypes: ['CategoryTag'],
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: () => `category`,
      providesTags: ['CategoryTag'],
    }),

    addCategory: builder.mutation({
      query: (body) => ({
        url: `category`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CategoryTag'],
    }),

    updateCategory: builder.mutation({
      query: ({ id, body }) => ({
        url: `category/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['CategoryTag'],
    }),

    deleteCategory: builder.mutation({
      query: (id) => ({
        url: `category/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CategoryTag'],
    }),
  }),
});

export const { useAddCategoryMutation, useDeleteCategoryMutation, useGetCategoriesQuery, useUpdateCategoryMutation } =
  categoryApi;
