import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { WS_URL } from '../config';
import { useLazyGetLatestMessageQuery } from '../redux/api/messageApi';
import axiosInstance from '../utils/axios';

const WebSocketContext = createContext({ messages: [] });

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const [messages, setMessages] = useState([]);
  const [latestMessages, setLatestMessages] = useState([]);
  const [triggerGetLatestMessage] = useLazyGetLatestMessageQuery();

  const ws = useRef(null);

  const getLatestMessage = (pharmacyUserId) =>
    triggerGetLatestMessage(pharmacyUserId).then(({ data }) => {
      setLatestMessages(data);
    });

  useEffect(() => () => {
      if (ws.current) {
        ws.current.close();
      }
      setMessages([]);
      setLatestMessages([]);
    }, []);

  const connect = async (receiverId, pharmacyUserId) => {
    disconnect();

    
    const { data } = await axiosInstance.get(
      pharmacyUserId ? `message?userId=${receiverId}&pharmacyUserId=${pharmacyUserId}` : `message?userId=${receiverId}`
    );

    setMessages(data);

    const token = localStorage.getItem('accessToken');

    if (pharmacyUserId) ws.current = new WebSocket(`${WS_URL}?token=${token}&pharmacy=${pharmacyUserId}`);
    else ws.current = new WebSocket(`${WS_URL}?token=${token}`);

    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);

      if (message.type === 'latestMessageUpdate') {
        setLatestMessages((prev) => {
          // Check if the conversation already exists
          const existingIndex = prev.findIndex((con) => con._id === message._id);

          const updatedConversations = [...prev];
          if (existingIndex !== -1) {
            // If it exists, update the existing conversation
            updatedConversations[existingIndex] = message;
          } else {
            // If it's a new conversation, add it
            updatedConversations.push(message);
          }

          // Sort conversations by updatedAt in descending order
          updatedConversations.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

          return updatedConversations;
        });
      }

      if (message.type === 'newMessage' && message?.data) setMessages((prev) => [...prev, message?.data]);
    };

    ws.current.onclose = () => {
      console.log('WebSocket Disconnected');
      // handle disconnection
    };

    ws.current.onerror = (error) => {
      console.error('WebSocket Error: ', error);
      // handle error
    };
  };

  const disconnect = () => {
    if (ws.current) {
      ws.current.close();
    }
    setMessages([]);
    setLatestMessages([]);
  };

  const sendMessage = (message) => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify(message));
    }
  };

  return (
    <WebSocketContext.Provider
      value={{ messages, latestMessages, users, setUsers, connect, disconnect, sendMessage, getLatestMessage }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
