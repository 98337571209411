import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import DashboardLayout from '../layouts/dashboard';

// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import { GuestGuard, AuthGuard } from '../guards';
import PharmacyLayout from '../layouts/pharmacy';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Auth Routes
    {
      path: '/auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: '/pharmacy',
      element: (
        <AuthGuard>
          <PharmacyLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={'/pharmacy/dashboard'} replace />, index: true },
        { path: 'dashboard', element: <PharmacyDashboard /> },
        { path: 'users', element: <PharmacyUsers /> },
        { path: 'pharmacy-medicine', element: <PharmacyMedicine /> },
        { path: 'offer', element: <Offer /> },
        { path: 'home-page', element: <HomePage /> },
        { path: 'orders', element: <Orders /> },
        { path: 'article', element: <Article /> },
        { path: 'chat', element: <PharmacyChat /> },
      ],
    },
    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'pharmacies', element: <Pharmacies /> },
        { path: 'users', element: <Users /> },
        { path: 'medicine', element: <Medicine /> },
        { path: 'offer', element: <Offer /> },
        { path: 'home-page', element: <HomePage /> },
        { path: 'orders', element: <Orders /> },
        { path: 'article', element: <Article /> },
        { path: 'category', element: <Category /> },
        {
          path: 'chat',
          children: [
            { element: <Navigate to="/chat/new" replace />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// Dashboard
const PharmacyDashboard = Loadable(lazy(() => import('../pages/dashboard/PharmacyDashboard')));
const Pharmacies = Loadable(lazy(() => import('../pages/dashboard/Pharmacies')));
const PharmacyUsers = Loadable(lazy(() => import('../pages/dashboard/PharmacyUsers')));

const Category = Loadable(lazy(() => import('../pages/dashboard/Category')));
const Users = Loadable(lazy(() => import('../pages/dashboard/Users')));
const Medicine = Loadable(lazy(() => import('../pages/dashboard/Medicine')));
const PharmacyMedicine = Loadable(lazy(() => import('../pages/dashboard/PharmacyMedicine')));
const Offer = Loadable(lazy(() => import('../pages/dashboard/Offer')));
const Article = Loadable(lazy(() => import('../pages/dashboard/Article')));
const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')));
const HomePage = Loadable(lazy(() => import('../pages/dashboard/HomePage')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const PharmacyChat = Loadable(lazy(() => import('../pages/dashboard/PharmacyChat')));

// INVOICE
const Orders = Loadable(lazy(() => import('../pages/dashboard/Orders')));


// Error
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
