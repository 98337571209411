import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RTKBaseQuery } from '../../config';

export const analyticApi = createApi({
  reducerPath: 'analyticApi',
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  tagTypes: ['AnalyticTag'],
  endpoints: (builder) => ({
    getAnalytics: builder.query({
      query: (pharmacyId) => (pharmacyId ? `analytic/pharmacy?pharmacy=${pharmacyId}` : `analytic/pharmacy`),
      providesTags: ['AnalyticTag'],
    }),
    getAdminAnalytics: builder.query({
      query: (pharmacyId) => (pharmacyId ? `analytic/admin?pharmacy=${pharmacyId}` : `analytic/admin`),
      providesTags: ['AnalyticTag'],
    }),
  }),
});

export const { useGetAnalyticsQuery, useGetAdminAnalyticsQuery } = analyticApi;
