import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RTKBaseQuery } from '../../config';

export const articleApi = createApi({
  reducerPath: 'articleApi',
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  tagTypes: ['ArticleTag'],
  endpoints: (builder) => ({
    getArticles: builder.query({
      query: (pharmacyId) => (pharmacyId ? `article?pharmacy=${pharmacyId}` : `article`),
      providesTags: ['ArticleTag'],
    }),

    addArticle: builder.mutation({
      query: (body) => ({
        url: `article`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ArticleTag'],
    }),

    updateArticle: builder.mutation({
      query: ({ id, body }) => ({
        url: `article/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ArticleTag'],
    }),

    deleteArticle: builder.mutation({
      query: (id) => ({
        url: `article/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ArticleTag'],
    }),
  }),
});

export const { useAddArticleMutation, useDeleteArticleMutation, useGetArticlesQuery, useUpdateArticleMutation } =
  articleApi;
