import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RTKBaseQuery } from "../../config";

export const bankCardApi = createApi({
  reducerPath: "bankCardApi",
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  tagTypes: ["BankCardTag"],
  endpoints: (builder) => ({
    getBankCards: builder.query({
      query: () => `bank-card`,
      providesTags: ["BankCardTag"],
    }),

    addBankCard: builder.mutation({
      query: (body) => ({
        url: `bank-card`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["BankCardTag"],
    }),

    updateBankCard: builder.mutation({
      query: ({ id, body }) => ({
        url: `bank-card/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["BankCardTag"],
    }),

    deleteBankCard: builder.mutation({
      query: (id) => ({
        url: `bank-card/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BankCardTag"],
    }),
  }),
});

export const {
  useAddBankCardMutation,
  useDeleteBankCardMutation,
  useGetBankCardsQuery,
  useUpdateBankCardMutation,
} = bankCardApi;
