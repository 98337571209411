import { createSlice } from '@reduxjs/toolkit';
// ----------------------------------------------------------------------

const initialState = {
  pharmacies: [],
  selectedPharmacy: null,
};

const slice = createSlice({
  name: 'pharmacy',
  initialState,
  reducers: {
    // SET PHARMACIES
    setPharmacies(state, action) {
      state.pharmacies = action.payload;
    },
    // SELECT PHARMACY
    setSelectedPharmacy(state, action) {
      const pharmacy = action.payload;
      state.selectedPharmacy = pharmacy;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setPharmacies, setSelectedPharmacy } = slice.actions;
