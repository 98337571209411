import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

import { setSession } from '../utils/jwt';
import { useGetAuthUserQuery } from '../redux/api/userApi';
import { useLoginMutation } from '../redux/api/authApi';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const { data, isError, isSuccess } = useGetAuthUserQuery();

  const [login] = useLoginMutation();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (isSuccess) {
      const { token, user } = data;
      setSession(token);

      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: true,
          user,
        },
      });
    }
    if (isError) {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [data, isError, isSuccess]);

  const handleLogin = async (email, password) => {
    try {
      const response = await login({
        email,
        password,
      }).unwrap();
      const { token, user } = response;
      if (user?.role !== 'admin') throw new Error('Only Admins are allowed!');

      setSession(token);
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });
    } catch (error) {
      alert(error?.message || error?.data?.message);
    }
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login: handleLogin,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
